import React, { useState } from 'react'
import deckMolhadoImg from '../../assets/deck-molhado-no-text.png'
import MaskedInput from 'react-text-mask'
import { Container } from './styles'
import ScrollAnimation from 'react-animate-on-scroll'
import { api, api_prod } from '../../services/api'

export function SectionFormulario() {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [formSend, setFormSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alertMsg, setAlertMsg] = useState(false)

/*   function openDialog() {
    const dialog = document.querySelector("dialog");
    dialog.style.display = "flex";
  } */
  ///forma-de-pagamento?id_cliente=4
  const FormadePagamento = {
    error:false,
    message:'sucesso!',
    results:[{
      id:1,
      nome:'Dinheiro'
    },{
      id:2,
      nome:'PIX'
    },{
      id:3,
      nome:'Cartão de Crédito'
    },{
      id:4,
      nome:'Cartão de Débito'
    },{
      id:5,
      nome:'Deposito Antecipado'
    },{
      id:6,
      nome:'Carteira',    
    },{
      id:7,
      nome:'Boleto'
    },{
      id:8,
      nome:'Cheque'
    }]
  }
  //tabela?id_forma_pagamento=1 (Dinheiro)
  const Tabela = {
    error:false,
    message:'Sucesso!',
    results:[
      {
        
      }
    ]
  }

  function handleSubmit(event) {
    setLoading(true)
    setFormSend(false)
    event.preventDefault()
    if (nome && email && telefone) {
      api_prod
      .get(
        `/empreendimentos/add-subscriber?venture_id=5&phone=${telefone}&email=${email}&name=${nome}&checkbox=true`,
        {
          venture_id: 2,
          phone: telefone,
          email: email,
          name: nome,
          checkbox: true,

          // empreendimento_id: 2, // Lux Design Concept
          // empreendimento_id: 4, // Lux Design Living
        }
      ).then(function (response) {
          console.log(response.data)
          setFormSend(true)
          setLoading(false)
          setAlertMsg(false)
         /*  openDialog() */
         window.location.assign('https://quadra.com.vc/formulario-agradecimento')
        })
        .catch(function (error) {
          console.error(error)
        })
    } else {
      handleSumitOver()
    }
    setNome('')
    setEmail('')
    setTelefone('')
  }

  function handleSumitOver() {
    setAlertMsg(true)
  }

  return (
    <Container>
      <img src={deckMolhadoImg} alt="" className="background" />
      <form className="section-formulario-form" onSubmit={handleSubmit}>
        <div className="header">
          <div>
            <h3>Interessado no Imóvel?</h3>
            <p>Entre em contato conosco e tire suas dúvidas</p>
          </div>
        </div>
        <div className="content-inputs">
          <ScrollAnimation animateIn="animate__fadeInDown">
            <label htmlFor="">Nome</label>
            <input
              type="text"
              placeholder="Informe seu nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInDown">
            <label htmlFor="">E-mail</label>
            <input
              type="email"
              placeholder="Informe  seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInDown">
            <label htmlFor="">Número Telefone/Whatsapp</label>
            <MaskedInput
              className="input-theme"
              placeholder="(91) 99999-9999"
              mask={[
                '(',
                /[1-9]/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInDown">
            {loading ? (
              <input type="submit" value="Enviando sua mensagem" />
            ) : (
              <input type="submit" value="Quero mais informações" />
            )}
          </ScrollAnimation>

          {formSend && (
            <p className="text-dark mt-4">
              Mensagem enviada, retornaremos em breve.
            </p>
          )}
          {alertMsg && (
            <p className="text-dark mt-4">
              Por favor, preencha todos os campos.
            </p>
          )}
        </div>
      </form>
    </Container>
  )
}

